<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height">
      <v-col cols="12" sm="8" md="8" class="mx-auto my-auto">
        <v-card
          color="#FAFFF5"
          style="border: 2px solid white"
          elevation="0"
          class="rounded-xl"
        >
          <v-row>
            <v-col
              cols="12"
              :sm="$vuetify.display.mobile ? 0 : 6"
              class="d-flex flex-column justify-center pa-9"
            >
              <h1 class="mb-10">Entrar na sua conta</h1>
              <v-form class="w-100">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  variant="outlined"
                  base-color="blue-grey-lighten-3"
                  color="stv-green-darken"
                  density="compact"
                  :rules="[required]"
                  bg-color="#ffffff"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  label="Senha"
                  type="password"
                  variant="outlined"
                  base-color="blue-grey-lighten-3"
                  color="stv-green-darken"
                  density="compact"
                  :rules="[required]"
                  bg-color="#ffffff"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-btn variant="text" size="x-small" @click="forgotPassword"
                      >Esqueci a minha senha</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      color="#AFC779"
                      class="float-right text-white"
                      variant="flat"
                      rounded="0"
                      :disabled="disable"
                      @click="login"
                      >Entrar</v-btn
                    >
                  </v-col>
                </v-row>
                <v-alert
                  v-show="error"
                  class="mt-3"
                  variant="outlined"
                  type="warning"
                  prominent
                  border="top"
                >
                  {{ error }}
                </v-alert>
              </v-form>
            </v-col>

            <v-col v-show="!$vuetify.display.mobile" cols="12" sm="6">
              <v-img src="/img/login.png" aspect-ratio="1"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useAuthUserStore } from "~/store/users";

const useAuthStore = useAuthUserStore();
const { setAuthenticatedUser } = useAuthStore;

definePageMeta({
  layout: "blank",
});

const router = useRouter();
const email = ref("");
const password = ref("");
const error = ref(false);
const disable = ref(true);

const login = async () => {
  try {
    const response = await fetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify({
        email: email.value,
        password: password.value,
      }),
    });
    if (response.status === 401) {
      error.value = "Esse usuário não é um franqueado ou técnico.";
      return;
    }
    if (response.status !== 200) {
      error.value = "Ocorreu um erro ao tentar fazer login.";
      return;
    }
    const result = await response.json();
    setAuthenticatedUser(result);
    await navigateTo("/");
  } catch (error) {
    error.value = error.message;
    console.error(error);
  }
};

const forgotPassword = () => {
  router.push("/recuperar-senha");
};

watch([email, password], () => {
  disable.value = !email.value || !password.value;
});

const required = (v) => {
  return !!v || "Esse campo é obrigatório";
};
</script>

<style lang="scss" scoped></style>
